<template>
  <th-wrapper :title="$t('common.titles.general_info.title')" class="m-8">
    <el-row :gutter="0">
      <el-col class="form-column" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <el-form
          ref="form"
          v-loading="loading"
          :model="form"
          :rules="rules"
          label-position="top"
          label-width="120px"
          class="edit-form"
        >
          <el-row :gutter="20">
            <el-col :lg="8" :sm="24">
              <el-form-item
                :label="$t('common.headers.name.title')"
                prop="name"
                for="name"
              >
                <el-input id="name" v-model="form.name" />
              </el-form-item>
              <el-form-item
                :label="$t('common.headers.description.title')"
                prop="description"
                for="description"
              >
                <el-input
                  id="description"
                  v-model="form.description"
                  type="textarea"
                  :rows="4"
                />
              </el-form-item>
            </el-col>
            <el-col :lg="8" :sm="24">
              <el-form-item
                :label="$t('pages.reasons.all.headers.navigation')"
                prop="navigation"
                for="navigation"
              >
                <el-select id="navigation" v-model="navigation">
                  <el-option
                    v-for="item in allowedNavigation"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item
                :label="$t('common.headers.type.title')"
                prop="type"
                for="type"
              >
                <el-select
                  id="type"
                  v-model="form.type"
                  :placeholder="
                    $t('pages.reasons.edit.form.properties.type.placeholder')
                  "
                >
                  <el-option
                    v-for="item in allowedTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
  </th-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import th from '@tillhub/javascript-sdk'
import Decimal from 'decimal.js-light'
import pick from 'just-pick'
import safeGet from 'just-safe-get'
import safeSet from 'just-safe-set'

function genInitialData() {
  return {
    name: null,
    description: null,
    behavior: {
      stock: 'restock',
      stock_location: 'current',
      navigation: 'payment_view'
    },
    type: 'refund'
  }
}

function makeHandleableBody(payload, form) {
  return pick(
    payload,
    Object.keys(form).filter((item) => ![].includes(item))
  )
}

export default {
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loading: false,
      valid: false,
      form: genInitialData(),
      rules: {
        name: [
          {
            required: true,
            message: this.$t('pages.reasons.edit.form.rules.name.required'),
            trigger: 'blur'
          },
          {
            min: 3,
            max: 128,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 3,
              max: 128
            }),
            trigger: 'blur'
          }
        ],
        description: [
          {
            max: 4000,
            message: this.$t('common.forms.rules.min_max_length', {
              max: 4000
            }),
            trigger: 'blur'
          }
        ]
      },
      payload: {},
      reasons: [],
      taxOptions: [],
      accounts: [],
      accountsOptions: [],
      allowedTypes: [
        {
          value: 'refund',
          label: this.$t(
            'pages.reasons.edit.form.properties.types.refund.label'
          )
        },
        {
          value: 'stock_change',
          label: this.$t(
            'pages.reasons.edit.form.properties.types.stock_change.label'
          )
        },
        {
          value: 'expense',
          label: this.$t(
            'pages.reasons.edit.form.properties.types.expense.label'
          )
        },
        {
          value: 'deposit',
          label: this.$t(
            'pages.reasons.edit.form.properties.types.deposit.label'
          )
        },
        {
          value: 'pos_price_change',
          label: this.$t(
            'pages.reasons.edit.form.properties.types.pos_price_change.label'
          )
        }
      ],
      allowedNavigation: [
        {
          value: 'payment_view',
          label: this.$t(
            'pages.reasons.edit.form.properties.types.payment_view.label'
          )
        },
        {
          value: 'carts',
          label: this.$t('pages.reasons.edit.form.properties.types.carts.label')
        },
        {
          value: 'ask',
          label: this.$t('pages.reasons.edit.form.properties.types.ask.label')
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),
    isNew() {
      if (this.$route.params.id && this.$route.params.id === 'new') return true
      return !this.$route.params.id
    },
    navigation: {
      get() {
        return safeGet(this.form, 'behavior.navigation')
      },
      set(location) {
        safeSet(this.form, 'behavior.navigation', location)
      }
    }
  },
  watch: {
    visible: function (newValue, oldValue) {
      if (oldValue && !newValue) this.resetForm()
      if (!newValue) this.resetData()
      if (newValue && !this.isNew) this.fetch(this.$route.params.id)
    }
  },
  async mounted() {
    if (!this.isNew) this.fetch(this.$route.params.id)
  },
  methods: {
    async fetch(id) {
      try {
        const inst = th.reasons()

        this.loading = true

        const { data = {} } = await inst.get(id)

        if (data.id) {
          this.handleItem(data)
        }

        this.loading = false
      } catch (err) {
        this.loading = false
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.reason.singular')
          })
        })
      }
    },
    handleItem(item) {
      this.payload = item
      this.form = {
        ...pick(item, Object.keys(this.form))
      }
    },
    submitForm(formName) {
      this.validate('form', async (valid) => {
        if (!valid) {
          return this.$message({
            type: 'warning',
            message: this.$t(
              'common.forms.rules.field_warnings.invalid_inputs.required'
            )
          })
        }

        const isNew = this.isNew
        isNew ? await this.create() : await this.alter(this.payload.id)

        if (isNew) {
          if (this.navigationAfterCreation === 'edit' && this.payload.id) {
            this.$router.push({
              name: 'reasons-edit',
              params: { id: this.payload.id }
            })
          } else {
            this.$router.push({ name: 'reasons-list' })
          }
        }
      })
    },

    validate(formName = 'form', cb) {
      this.$refs[formName].validate((valid) => {
        return cb(valid)
      })
    },
    resetForm(formName) {
      if (!this.$refs[formName]) return
      this.$refs[formName].resetFields()
    },
    resetData(formName) {
      this.form = genInitialData()
    },
    async alter(id) {
      const payload = {
        ...this.payload,
        ...this.form
      }

      try {
        const inst = th.reasons()
        this.loading = true

        const { data = {} } = await inst.put(
          payload.id,
          makeHandleableBody(payload, this.form)
        )

        if (data.id) {
          this.handleItem(data)
        }

        this.loading = false
        this.$message({
          type: 'success',
          message: this.$t('common.success.action.update.single', {
            resource: this.$t('common.resource.reason.singular')
          })
        })
        this.$emit('handled-item')
        this.$emit('altered-item')
      } catch (err) {
        this.loading = false
        this.$logException(err, {
          message: this.$t('common.error.action.update.single', {
            resource: this.$t('common.resource.reason.singular')
          })
        })
      }
    },
    async create() {
      const payload = {
        ...this.form
      }

      try {
        const inst = th.reasons()
        this.loading = true

        // we will handle currency and timezone as configuration, TODO: implement
        const { data = {} } = await inst.create(
          makeHandleableBody(payload, this.form)
        )

        if (data.id) {
          this.handleItem(data)
        }

        this.loading = false
        this.$message({
          type: 'success',
          message: this.$t('common.success.action.create.single', {
            resource: this.$t('common.resource.reason.singular')
          })
        })
        this.$emit('handled-item')
        this.$emit('new-item')
      } catch (err) {
        this.loading = false
        this.$logException(err, {
          message: this.$t('common.error.action.create.single', {
            resource: this.$t('common.resource.reason.singular')
          })
        })
      }
    },
    parseRate(val) {
      if (!Number.isFinite(val)) return null

      const d = new Decimal(val)

      return d.times(100).toNumber()
    },
    handleRate(val) {
      if (!Number.isFinite(Number(val))) return

      const d = new Decimal(val)

      this.form.rate = d.dividedBy(100).toNumber()
    }
  }
}
</script>
