<template>
  <th-page-wrapper
    class="reasons-edit"
    color="var(--secondary-color)"
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
  >
    <reasons-form ref="form" @new-item="goToEdit()" />
  </th-page-wrapper>
</template>

<script>
import ReasonsForm from './components/form.vue'
import th from '@tillhub/javascript-sdk'

export default {
  name: 'ReasonsEdit',
  components: {
    ReasonsForm
  },
  data() {
    return {
      loadingExport: false,
      exportMapList: [
        {
          name: 'code',
          label: this.$t('pages.reasons.items.headers.code')
        },
        {
          name: 'amount',
          label: this.$t('pages.reasons.items.headers.amount')
        },
        {
          name: 'location_custom_id',
          label: this.$t('pages.reasons.items.headers.location_custom_id')
        }
      ]
    }
  },
  computed: {
    isNew() {
      return !this.$route.params.id
    },
    actions() {
      return {
        permissionPrefix: 'inventory:processes',
        returnedPath: { name: 'reasons-list' },
        isNew: this.isNew
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    },
    goBack() {
      this.$router.push({ name: 'reasons-list' })
    },
    goToEdit() {
      this.$refs.form?.payload?.id
        ? this.$router.push({
            name: 'reasons-edit',
            params: { id: this.$refs.form?.payload?.id }
          })
        : this.goBack()
    },
    async handleDelete() {
      const payload = this.$refs.form.payload
      const confirm = await this.$askToDelete(payload.name || payload.id)
      if (confirm) this.deleteReason(payload)
    },
    async deleteReason(payload) {
      const successMessage = this.$t('common.success.action.delete.single', {
        resource: this.$t('common.resource.reason.singular')
      })
      const errorMessage = this.$t('common.error.action.delete.single', {
        resource: this.$t('common.resource.reason.singular')
      })

      try {
        const inst = th.reasons()
        await inst.delete(payload.id)
        this.$message({
          type: 'success',
          message: successMessage
        })
        this.goBack()
      } catch (err) {
        this.$logException(err, {
          message: errorMessage
        })
      }
    }
  }
}
</script>

<style scoped></style>
